import React from 'react';
import Layout from '../components/Layout';
import Landing from '../sections/Landing';
import About from '../sections/About';
import Projects from '../sections/Projects';
// import Writing from '../sections/Writing';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const MATOMO = 'https://matomo.hopeplex.com';

const instance = createInstance({
  urlBase: MATOMO,
  siteId: 1, // optional, default value: `1`
  // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
});

const IndexPage = () => (
  <MatomoProvider value={instance}>
    <Layout>
      <Header />
      <Landing />
      <About />
      <Projects />
      {/* <Writing /> */}
      <Footer />
    </Layout>
  </MatomoProvider>
);

export default IndexPage;
